import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import keyboard from '../images/christian-wiediger-WkfDrhxDMC8-unsplash.jpg';

const useStyles = makeStyles((theme) => ({
  hero: {
    height: '45vh',
    backgroundImage: `linear-gradient(to bottom,rgba(34,40,49,1), rgba(34,40,49,.85)), url('${keyboard}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: (handleMargin) => `${handleMargin()}`,
    clipPath: 'polygon(0 0,100% 0, 100% 40vh, 0 100%)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      clipPath: 'polygon(0 0,100% 0, 100% 42vh, 0 100%)',
    },
  },
  textBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
  },
  heading: {
    color: theme.palette.common.white,
    backfaceVisibility: 'hidden',
    marginBottom: '6rem',
  },
  headingMain: {
    display: 'block',
    textTransform: 'uppercase',
    ...theme.typography.header,
    animation: 'moveInBottom 0.5s ease-out',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
}));

const HeroTwo = ({ title, marginTop = '-2rem' }) => {
  const handleMargin = () => marginTop;
  const classes = useStyles(handleMargin);
  return (
    <div className={classes.hero}>
      <div className={classes.textBox}>
        <h1 className={classes.heading}>
          <span className={`${classes.heading} ${classes.headingMain}`}>
            {title}
          </span>
        </h1>
      </div>
    </div>
  );
};

export default HeroTwo;
